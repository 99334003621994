import React from 'react';
import Popup from 'reactjs-popup';
import { useRef } from 'react';
import { moveMessage } from '../api/amq-rme-api';
import 'reactjs-popup/dist/index.css';


export default function PopUpMoveButtom({props}) {
    const targetQueue = useRef('');
    const originQueue = 'a'
    //console.log(props.messageData)
    //console.log(props.queue)
    /*
    const handleOnChangeEvent = (event) => {
        console.log(event.target.value);
        if(event.target.value === 'N/A'){
            alert('Please select a value');
        }
        else {
            alert(`Selected value is ${event.target.value}`)
        }
        event.preventDefault();
    };
*/
    const handleSendButton = (JMScorrelationID, event) => {

        /*console.log(`target queue ${targetQueue.current.value}`);
        console.log(`origin queue ${props.queue}`);
        console.log(`correlationId ${JMScorrelationID}`);*/
        const userConfirmed = window.confirm(
          `Are you sure you want to MOVE this record to ${targetQueue.current.value}?`
        );
        
        if(userConfirmed){
          console.log('User confirmed moving a message');
          
          moveMessage(props.queue, targetQueue.current.value, JMScorrelationID)
          .then((response) =>{
            return response.json();
          })
          .then((data)=>{
            console.log(data);
            window.location.reload();
          })
        } else {
          console.log("Move operation canceled by the user.");
        }
        event.preventDefault();
    }

    return (
        <Popup trigger={<button class="button is-warning"> Move</button>} position="right center">
        <div class="container">
            <div class="select is-small">
                <select name="queues" id="cars" ref={targetQueue}>
                    <option value="N/A">--Select Queue--</option>
                    <option value="POC">POC</option>
                    <option value="POC.aws.dev">POC.aws.dev</option>
                    <option value="POC.aws.test">POC.aws.test</option>
                    <option value="POC.aws.stg">POC.aws.stg</option>
                </select>
            </div>
            <div>
                <button class="button is-dark is-small" onClick={(e) => handleSendButton(props.messageData.JMSCorrelationID, e)}>Send</button>
            </div>
        </div>

        </Popup>
    )
};