import { useLocation } from 'react-router-dom';

export default function MessageView(messageData) {
    let { state } = useLocation();
    let intPropertiesList = Object.keys(state.IntProperties)
    let stringPropertiesList = Object.keys(state.StringProperties)
    console.log(Object.keys(state.IntProperties));
    return (
        <div class="panel">
            <div class="table-container">
                <h1 class="panel-heading">Unique MessageID: {state.JMSMessageID}</h1>
                <table class="table is-bordered">
                    <tbody class="is-bordered">
                        <tr>
                            <th class="is-primary">JMSMessageID</th>
                            <td>{state.JMSMessageID}</td>
                            <th class="is-primary">JMSCorrelationID</th>
                            <td>{state.JMSCorrelationID}</td>
                        </tr>
                        <tr>
                            <th class="is-primary">JMSDeliveryMode</th>
                            <td>{state.JMSDeliveryMode}</td>
                            <th class="is-primary">JMSExpiration</th>
                            <td>{state.JMSExpiration}</td>
                        </tr>
                        <tr>
                            <th class="is-primary">JMSDestination</th>
                            <td>{state.JMSDestination}</td>
                            <th class="is-primary">OriginalDestination</th>
                            <td>{state.OriginalDestination}</td>
                        </tr>
                        <tr>
                            <th class="is-primary">JMSPriority</th>
                            <td>{state.JMSPriority}</td>
                            <th class="is-primary">JMSTimestamp</th>
                            <td>{state.JMSTimestamp}</td>
                        </tr>
                        {intPropertiesList.map((intProperty) => (
                            <tr>
                                <th class="is-primary">{intProperty}</th>
                                <td>{state.IntProperties[intProperty]}</td>
                            </tr>
                        ))}
                        {stringPropertiesList.map((strProperty) => (
                            <tr>
                                <th class="is-primary">{strProperty}</th>
                                <td>{state.StringProperties[strProperty]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div class="panel-block">
                <h1 class="panel-heading">Message Body</h1>
                <div class="notification is-secondary">
                    {state.Text}
                </div>
            </div>
        </div>
    )
}