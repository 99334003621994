import QueueTable from "../components/queueTableDiv.js";

export default function QueuesView() {
  const PCQueues = ['POC.demo.docmgmt.retry','POC.demo.docmgmt.rme']
    return (
      <div class="panel">
        {PCQueues.map((queue) => (
          <QueueTable queueName={ queue }/>
        ))}
      </div>
    )
}