import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { getMessagesByQueue, deleteMessageById } from '../api/amq-rme-api';
import LoaderDiv from "../components/loaderDiv";
import PopUpMoveButtom from './movePopUpDiv';

export default function QueueTable({queueName}) {
    const [isLoading, setIsLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    
    useEffect(() => {
      getMessagesByQueue(queueName)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setIsLoading(false);
        setMessages(data);
      });
    }, [queueName]);

    if(isLoading){
      return <LoaderDiv />
    }

    const deletebuttomHandler = (message,queueName,event) => {
      console.log(message);
      console.log(queueName);
      const userConfirmed = window.confirm(
        "Are you sure you want to delete this record?"
      );
      if(userConfirmed){
        console.log('User confirmed cancelation');
        deleteMessageById(message, queueName)
        .then((response) =>{
          return response.json();
        })
        .then((data)=>{
          console.log(data)
          window.location.reload();
        })
      } else {
        console.log("Delete operation canceled by the user.");
      }
      event.preventDefault();
    };

    return (
        <div>
            <h1 class="panel-heading ">Queue: {queueName}</h1>
            <div class="table-container">
              <table class="table is-bordered is-striped">
                <thead>
                  <tr>
                    <th>Message#</th>
                    <th>JMSCorrelationID</th>
                    <th>JMSMessageID</th>
                    <th>Exception Cause</th>
                    <th>TimeStamp</th>
                    <th>JMSDestination</th>
                    <th>Options</th>
                  </tr>
                </thead>
                <tbody>
                  { messages.map((message,index) => (
                    <tr key={message.JMSMessageID}>
                        <td><h3>{ index + 1 }</h3></td>
                        <td><h3>{ message.JMSCorrelationID }</h3></td>
                        <td><h3>{ message.JMSMessageID }</h3></td>
                        <td><h3>{ message.StringProperties.EXCEPTION_CAUSE }</h3></td>
                        <td><h3>{ message.JMSTimestamp }</h3></td>
                        <td><h3>{ message.JMSDestination }</h3></td> 
                        <td>
                          <Link class="button is-info" to="/messageView" state={ message }>
                              view
                          </Link>
                          <button class="button is-danger" onClick={(e) => deletebuttomHandler(message.JMSMessageID, queueName, e)} >Delete</button>
                          <PopUpMoveButtom props={{ messageData:message, queue:queueName}}/>
                        </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                    <tr>
                        <th>Message#</th>
                        <th>JMSCorrelationID</th>
                        <th>JMSMessageID</th>
                        <th>Exception Cause</th>
                        <th>TimeStamp</th>
                        <th>JMSDestination</th>
                        <th>Options</th>
                    </tr>
                </tfoot>
              </table>
            </div>
        </div>
    )
}