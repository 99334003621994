//import React, { useState } from 'react';
import { useRef } from "react";
import { useNavigate } from 'react-router-dom';

export default function LoginPage() {
    const navigate = useNavigate();
    const userRef = useRef();
    const passwordRef = useRef();
    
    /*
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');

    const handleUserInputChange = (event) => {
        setUser(event.target.value);
    };

    const handlePasswordInputChange = (event) => {
        setPassword(event.target.value);
    };
    */
    

    const handleSubmitButton = (event) => {
        if(userRef.current.value === 'admin' && passwordRef.current.value === 'admin'){
            alert('Welcome Admin');
            navigate('/queues');
        } else {
            alert('Admin only');
        }
        event.preventDefault();
    };

    return (
        <section class="hero is-primary is-fullheight">
            <div class="hero-body">
                <div class="container">
                    <div class="columns is-centered">
                        <div class="column is-4-desktop">
                            <form class="box" onSubmit={handleSubmitButton}>
                                <div class="field">
                                    <label class="label">Email</label>
                                    <div class="control">
                                        <input type="text" placeholder="userName" class="input" ref={userRef} required />
                                    </div>
                                </div>
                                <div class="field">
                                    <label class="label">Password</label>
                                    <div class="control">
                                        <input type="password" placeholder="*******" class="input" ref={passwordRef} required />
                                    </div>
                                </div>
                                <div class="field">
                                    <label class="checkbox">
                                        <input type="checkbox"/>
                                        Remember me
                                    </label>
                                </div>
                                <div class="field">
                                    <button class="button is-success">
                                        Login
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}