export default function SiteFooter() {
    return (
        <div class="hero-foot">
            <nav class="tabs">
                <div class="container">
                    <ul>
                        <li><a href="www.google.com">Overview</a></li>
                        <li><a href="www.google.com">Modifiers</a></li>
                        <li><a href="www.google.com">Grid</a></li>
                        <li><a href="www.google.com">Elements</a></li>
                        <li><a href="www.google.com">Components</a></li>
                        <li><a href="www.google.com">Layout</a></li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}