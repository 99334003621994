const apiKey = "ROZ5aSDvRW49n0huddtvkap6tqnlXbBf8DFxMLxu";
const baseUrl = "https://dev-amqrme.api-dev.wawanesalabs.com";

export async function getMessagesByQueue(queueName){
    return await fetch(`${baseUrl}/amqrme/search/queue/${queueName}`,
    {
        headers:{
            'x-api-key':apiKey
        }
    })
    .catch(error =>{
        console.error('Fetch Failed', error);
    })
}

export async function deleteMessageById(JMSMessageID, queueName) {
    return await fetch(`${baseUrl}/amqrme/remove/messageid/${JMSMessageID}?queue=${queueName}`,
    {
        method: 'DELETE',
        headers:{
            'x-api-key':apiKey
        }
    })
    .catch(error =>{
        console.error('Delete request Failed', error);
    })
}

export async function moveMessage(originQueue, targetQueue, correlationId) {
    return await fetch(`${baseUrl}/amqrme/move/messages?origin_queue=${originQueue}&target_queue=${targetQueue}`,
    {
        method: 'POST',
        headers:{
            'x-api-key':apiKey
        },
        body: JSON.stringify({parameters:[correlationId]})
    })
    .catch(error =>{
        console.error('Move request Failed', error);
    })
}