import 'bulma/css/bulma.min.css';
import LoginPage from './views/loginView';
import QueuesView from './views/queuesView';
import QueueView from './views/queueView';
import MessageView from './views/messageView';
import Navbar from './components/siteNavBar';
import SiteFooter from './components/siteFooter';
import { Route, Routes } from 'react-router-dom';

const App = () => {
      return (
        <div className="App container">
          <Navbar />
          <Routes>
            <Route path='/' element={<LoginPage />} />
            <Route path='/queues' element={<QueuesView />} />
            <Route path='/admin' element={<QueueView />} />
            <Route path='/messageView' element={<MessageView />} />
          </Routes>
          <SiteFooter/>
        </div>
      )
  }
  
export default App;