import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <div class="hero-head">
      <nav class="tabs is-toggle is-small">
        <ul>
          <li>
            <Link class="navbar-link is-arrowless" to="/">Login</Link>
          </li>
          <li>
            <Link class="navbar-link is-arrowless" to="/queues">POC Queues View</Link>
          </li>
          <li>
            <Link class="navbar-link is-arrowless" to="/admin">Admin view</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

